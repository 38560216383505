import { useEffect, useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { FeatureItem, ProductItem, Spinner, Svg } from '@snippets';
import { useColorSwatches } from '@hooks';
import { fetchProductsFromHandles } from '@utilities';
import { ProductSliderButton } from './ProductSliderButton';

export function ProductSlider({ cms, isFeatureSlider, products: _products }) {
  const {
    button,
    heading,
    productItem,
    products: productsFromCms,
    featureItems,
    section,
    slider,
    textColor,
  } = cms;

  const { swatchesMap } = useColorSwatches();

  const products = isFeatureSlider
    ? featureItems
    : productsFromCms || _products;

  const [swiper, setSwiper] = useState(null);
  const [activeProducts, setActiveProducts] = useState(
    isFeatureSlider ? featureItems : []
  );

  const { sliderStyle } = { ...slider };
  const slidesPerViewDesktop = slider?.slidesPerViewDesktop || 4;
  const slidesPerViewTablet = slider?.slidesPerViewTablet || 3.4;
  const slidesPerViewMobile = slider?.slidesPerViewMobile || 1.4;
  const isFullBleedAndCentered =
    sliderStyle === 'fullBleed' || sliderStyle === 'fullBleedWithGradient';
  const isLoop = isFullBleedAndCentered || sliderStyle === 'containedWithLoop';
  const maxWidthClass =
    section?.fullWidth || isFullBleedAndCentered
      ? 'max-w-none'
      : 'max-w-[var(--content-max-width)]';

  /*
    Need to only display ACTIVE Products. If products are set in CMS
    but is no longer published in Shopify, they need to be filtered
    out to prevent from being rendered on the page
  */

  useEffect(() => {
    if (!isFeatureSlider) {
      fetchProductsFromHandles(
        products?.map(({ product }) => product?.handle)
      ).then((fetchProducts) => {
        const _activeProducts = fetchProducts?.filter(
          (product) => product?.status === 'ACTIVE'
        );
        setActiveProducts(_activeProducts);
      });
    }
  }, [products, isFeatureSlider]);

  if (!activeProducts?.length) return null;

  return (
    <div
      className="py-contained"
      style={{
        backgroundColor: section?.backgroundColor,
      }}
    >
      <div className="m-auto flex flex-col items-center">
        {heading && (
          <h2
            className="text-title-h2 mb-6 px-4 text-center text-[24px] font-bold uppercase md:mb-10 md:text-[36px]"
            style={{ color: textColor }}
          >
            {heading}
          </h2>
        )}

        {activeProducts && (
          <Swiper
            centeredSlides={
              isFullBleedAndCentered &&
              activeProducts.length >= slidesPerViewMobile * 2
            }
            className={`relative w-full ${maxWidthClass} ${
              sliderStyle === 'fullBleedWithGradient'
                ? 'before:swiper-offset-gradient-270-left after:swiper-offset-gradient-270-right'
                : ''
            } ${
              productItem?.enableFeatureLayout && productItem?.enabledStarRating
                ? 'top-rated-slider pb-5 [&_.swiper-slide]:!h-auto'
                : ''
            }`}
            grabCursor
            loop={isLoop && activeProducts.length >= slidesPerViewMobile * 2}
            modules={[Navigation]}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              disabledClass: 'cursor-not-allowed opacity-20',
            }}
            onSwiper={setSwiper}
            slidesOffsetAfter={16}
            slidesOffsetBefore={16}
            slidesPerView={slidesPerViewMobile}
            spaceBetween={16}
            breakpoints={{
              768: {
                centeredSlides:
                  isFullBleedAndCentered &&
                  activeProducts.length >= slidesPerViewTablet * 2,
                loop:
                  isLoop && activeProducts.length >= slidesPerViewTablet * 2,
                slidesPerView: slidesPerViewTablet,
                spaceBetween: 20,
                slidesOffsetBefore: 32,
                slidesOffsetAfter: 32,
              },
              1024: {
                centeredSlides:
                  isFullBleedAndCentered &&
                  activeProducts.length >= slidesPerViewDesktop * 2,
                loop:
                  isLoop && activeProducts.length >= slidesPerViewDesktop * 2,
                slidesPerView: slidesPerViewDesktop,
                spaceBetween: 40,
                slidesOffsetBefore: 40,
                slidesOffsetAfter: 40,
              },
            }}
          >
            {swiper &&
              activeProducts.map((item, index) => {
                return (
                  <SwiperSlide
                    className={`${
                      productItem?.enableFeatureLayout &&
                      productItem?.enabledStarRating
                        ? 'overflow-hidden rounded-[14px] bg-white pb-5 shadow-[1px_10px_8px_1px_rgba(216,225,230,0.55)]'
                        : ''
                    }`}
                    key={index}
                  >
                    {isFeatureSlider ? (
                      <FeatureItem
                        enableFeatureLayout={productItem?.enableFeatureLayout}
                        item={item}
                      />
                    ) : (
                      <ProductItem
                        enabledColorNameOnHover={
                          productItem?.enabledColorNameOnHover
                        }
                        enabledColorSelector={productItem?.enabledColorSelector}
                        enableFeatureLayout={productItem?.enableFeatureLayout}
                        enableTopRatedLayout={
                          productItem?.enableFeatureLayout &&
                          productItem?.enabledStarRating
                        }
                        enabledQuickShop={productItem?.enabledQuickShop}
                        enabledStarRating={productItem?.enabledStarRating}
                        handle={item?.handle}
                        index={index}
                        swatchesMap={swatchesMap}
                      />
                    )}
                  </SwiperSlide>
                );
              })}

            {activeProducts.length > slidesPerViewDesktop && (
              <div className="z-1 absolute left-0 right-0 top-[calc(50%-28px)] md:px-8 xl:px-14">
                <div
                  className={`relative mx-auto ${maxWidthClass} ${
                    isFullBleedAndCentered ? 'min-[90rem]:max-w-full' : ''
                  }`}
                >
                  <div
                    className={`swiper-button-prev left-0 top-[calc(50%-1.6875rem)] !hidden !h-[3.5rem] !w-[3.5rem] rounded-full border border-border bg-white after:hidden lg:!flex ${
                      !isFullBleedAndCentered ? 'xl:-left-[1.6875rem]' : ''
                    }`}
                  >
                    <Svg
                      className="w-[1.25rem] text-black"
                      src="/svgs/arrow-left.svg#arrow-left"
                      title="Arrow Left"
                      viewBox="0 0 24 24"
                    />
                  </div>

                  <div
                    className={`swiper-button-next right-0 top-[calc(50%-1.6875rem)] !hidden !h-[3.5rem] !w-[3.5rem] rounded-full border border-border bg-white after:hidden lg:!flex ${
                      !isFullBleedAndCentered ? 'xl:-right-[1.6875rem]' : ''
                    }`}
                  >
                    <Svg
                      className="w-[1.25rem] text-black"
                      src="/svgs/arrow-right.svg#arrow-right"
                      title="Arrow Right"
                      viewBox="0 0 24 24"
                    />
                  </div>
                </div>
              </div>
            )}
          </Swiper>
        )}

        {!swiper && (
          <div className="relative min-h-[20rem]">
            <Spinner
              width="32"
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        )}

        {button?.text && (
          <ProductSliderButton
            button={button}
            buttonStyle={section?.buttonClasses}
            customButtonStyles={section?.customButtonStyles}
            enableFeatureLayout={productItem?.enableFeatureLayout}
          />
        )}
      </div>
    </div>
  );
}

ProductSlider.displayName = 'ProductSlider';
